define("clap-front/controllers/ask-password-reset", ["exports", "@ember/controller", "@ember/service", "@ember/object", "@glimmer/tracking", "@ember/string"], function (_exports, _controller, _service, _object, _tracking, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AskPasswordResetController = _exports.default = (_class = class AskPasswordResetController extends _controller.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "request", _descriptor, this);
      _initializerDefineProperty(this, "notifications", _descriptor2, this);
      _initializerDefineProperty(this, "isWaiting", _descriptor3, this);
    }
    async askPasswordReset(e) {
      e.preventDefault();
      this.isWaiting = true;
      let {
        email
      } = this;
      try {
        await this.request.post("password_reset/", {
          email: email
        });
        this.notifications.success("L'email a été envoyé");
        this.transitionToRoute('index');
      } catch (e) {
        e.errors.forEach(function (error) {
          let pointer = error.source.pointer.split('/').pop(-1);
          this.notifications.error((0, _string.capitalize)(pointer) + ' : ' + error.detail);
        }, this);
      }
      this.isWaiting = false;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "request", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isWaiting", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "askPasswordReset", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "askPasswordReset"), _class.prototype), _class);
});