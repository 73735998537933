define("clap-front/templates/components/clap-calendar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "wWq0WCxO",
    "block": "[[[10,\"button\"],[15,0,[29,[\"clap-btn ternary-icon clap-calendar-button \",[52,[30,1],\"has-selection\"]]]],[14,4,\"button\"],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"    \"],[8,[39,1],null,[[\"@icon\",\"@spin\"],[\"spinner-third\",true]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,1],null,[[\"@icon\"],[\"calendar-alt\"]],null],[1,\"\\n    \"],[10,0],[14,0,\"clap-calendar-container\"],[12],[1,\"\\n      \"],[8,[39,2],[[24,0,\"clap-calendar\"]],[[\"@center\",\"@onCenterChange\",\"@selected\",\"@onSelect\"],[[30,0,[\"center\"]],[28,[37,3],[[30,0,[\"onCenterChange\"]]],[[\"value\"],[\"date\"]]],[30,1],[28,[37,3],[[30,3]],[[\"value\"],[\"date\"]]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,4,[\"Nav\"]],null,null,null],[1,\"\\n        \"],[8,[30,4,[\"Days\"]],null,null,null],[1,\"\\n      \"]],[4]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13]],[\"@selected\",\"@loading\",\"@onSelect\",\"calendar\"],false,[\"if\",\"fa-icon\",\"power-calendar\",\"fn\"]]",
    "moduleName": "clap-front/templates/components/clap-calendar.hbs",
    "isStrictMode": false
  });
});