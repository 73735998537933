define("clap-front/controllers/projects", ["exports", "@ember/controller", "@ember/service", "@ember/object", "@glimmer/tracking"], function (_exports, _controller, _service, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ProjectsController = _exports.default = (_class = class ProjectsController extends _controller.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "moment", _descriptor, this);
      _initializerDefineProperty(this, "infinity", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "notifications", _descriptor4, this);
      _initializerDefineProperty(this, "iframeCheck", _descriptor5, this);
      _initializerDefineProperty(this, "selectedDate", _descriptor6, this);
      _initializerDefineProperty(this, "loading", _descriptor7, this);
      _initializerDefineProperty(this, "showAutoclaps", _descriptor8, this);
      _initializerDefineProperty(this, "showClapList", _descriptor9, this);
      _initializerDefineProperty(this, "showingPremiumModal", _descriptor10, this);
      _defineProperty(this, "queryParamsCustom", {
        infinityCache: 36000,
        draft: false,
        autoclap: false,
        perPage: 10,
        date: this.selectedDate
      });
    }
    onSelectDate(value) {
      // Deselects date if we click on the same date
      const sameDate = this.moment.moment(value.date).isSame(this.selectedDate, 'day');
      const newSelectedDate = sameDate ? null : value.date;
      this.selectedDate = newSelectedDate;
      this.filterProjects();
    }
    async filterProjects() {
      const formattedDateStr = this.selectedDate ? this.moment.moment(this.selectedDate).format().toString() : null;
      const queryParamsCustom = {
        ...this.queryParamsCustom,
        date: formattedDateStr,
        autoclap: this.showAutoclaps
      };
      this.loading = true;
      const projects = await this.infinity.model("video/project", queryParamsCustom);
      (0, _object.set)(this, 'model.projects', projects);
      this.loading = false;
      const firstProject = projects.firstObject;
      if (firstProject) {
        this.router.transitionTo("projects.view", firstProject.id);
      }
    }
    toggleShowAutoclaps() {
      this.showAutoclaps = !this.showAutoclaps;
    }
    hideClapList() {
      this.showClapList = false;
    }
    newButtonClicked() {
      if (this.model.user.can_create_new_project) {
        this.router.transitionTo("create.realty-select");
      } else {
        this.notifications.warning("Vous avez atteint le nombre maximum de vidéos gratuites.");
        this.showingPremiumModal = true;
      }
    }
    refreshModel() {
      this.send('refreshProjectsModel');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "moment", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "infinity", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "iframeCheck", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "selectedDate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "showAutoclaps", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "showClapList", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "showingPremiumModal", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onSelectDate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSelectDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterProjects", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "filterProjects"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleShowAutoclaps", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleShowAutoclaps"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hideClapList", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "hideClapList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newButtonClicked", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "newButtonClicked"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refreshModel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "refreshModel"), _class.prototype), _class);
});