define("clap-front/templates/components/hamburger", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "/9hMnsTJ",
    "block": "[[[10,\"button\"],[14,0,\"menu d-xl-none\"],[14,\"aria-label\",\"Main Menu\"],[14,4,\"button\"],[12],[1,\"\\n  \"],[10,\"svg\"],[14,\"width\",\"100\"],[14,\"height\",\"100\"],[14,\"viewBox\",\"0 0 100 100\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,0,\"line line1\"],[14,\"d\",\"M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246\\n    85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,0,\"line line2\"],[14,\"d\",\"M 20,50 H 80\"],[12],[13],[1,\"\\n    \"],[10,\"path\"],[14,0,\"line line3\"],[14,\"d\",\"M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754\\n    85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "clap-front/templates/components/hamburger.hbs",
    "isStrictMode": false
  });
});