define("clap-front/adapters/drf", ["exports", "@ember/object", "ember-django-adapter/adapters/drf", "clap-front/config/environment"], function (_exports, _object, _drf, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _drf.default.extend({
    host: (0, _object.computed)(function () {
      return _environment.default.APP.API_HOST;
    }),
    namespace: (0, _object.computed)(function () {
      return _environment.default.APP.API_NAMESPACE;
    })
  });
});