define("clap-front/helpers/string-contains", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.stringContains = stringContains;
  function stringContains(params /*, hash*/) {
    if (params[0]) {
      return params[0].toLowerCase().indexOf(params[1]) !== -1;
    } else {
      return false;
    }
  }
  var _default = _exports.default = (0, _helper.helper)(stringContains);
});