define("clap-front/components/head-content", ["exports", "@ember/service", "@ember/component", "clap-front/templates/head"], function (_exports, _service, _component, _head) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    tagName: '',
    model: (0, _service.inject)('head-data'),
    layout: _head.default
  });
});