define("clap-front/helpers/format-seconds", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.timeToSeconds = _exports.secondsToTime = _exports.pad = _exports.default = void 0;
  // converts number to string and fills with leading zeros
  const pad = (num, places) => String(num).padStart(places, '0');
  _exports.pad = pad;
  const secondsToTime = secs => {
    secs = Math.round(secs);
    let hours = Math.floor(secs / (60 * 60));
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);
    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    let obj = {
      h: hours,
      m: minutes,
      s: seconds
    };
    return obj;
  };
  _exports.secondsToTime = secondsToTime;
  const timeToSeconds = (h = 0, m = 0, s = 0) => h * 3600 + m * 60 + parseInt(s);
  _exports.timeToSeconds = timeToSeconds;
  var _default = _exports.default = (0, _helper.helper)(function formatSeconds(positional) {
    let time = secondsToTime(positional);
    if (positional >= 3600) {
      return `${pad(time.h, 2)}:${pad(time.m, 2)}:${pad(time.s, 2)}`;
    } else {
      return `${pad(time.m, 2)}:${pad(time.s, 2)}`;
    }
  });
});