define("clap-front/controllers/create/informations", ["exports", "@ember/controller", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember/object/computed", "clap-front/config/environment"], function (_exports, _controller, _tracking, _object, _service, _computed, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let CreateInformationsController = _exports.default = (_dec = (0, _computed.filterBy)('selectedTemplate.templatelayerSet', 'type', 'image'), _dec2 = (0, _computed.sort)('imageLayersNotSorted', 'templateLayerSorting'), _dec3 = (0, _computed.filterBy)('selectedTemplate.templatelayerSet', 'type', 'video'), _dec4 = (0, _computed.sort)('videoLayersNotSorted', 'templateLayerSorting'), _dec5 = (0, _computed.filterBy)('selectedTemplate.templatelayerSet', 'type', 'audio'), _dec6 = (0, _computed.sort)('audioLayersNotSorted', 'templateLayerSorting'), _dec7 = (0, _computed.filterBy)('model.project.template.templatelayerSet', 'type', 'image'), _class = class CreateInformationsController extends _controller.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "notifications", _descriptor4, this);
      _initializerDefineProperty(this, "selectedTemplate", _descriptor5, this);
      _initializerDefineProperty(this, "formLayers", _descriptor6, this);
      _initializerDefineProperty(this, "loading", _descriptor7, this);
      _initializerDefineProperty(this, "imagesNotFilled", _descriptor8, this);
      _initializerDefineProperty(this, "sending", _descriptor9, this);
      _initializerDefineProperty(this, "showPremiumModal", _descriptor10, this);
      _initializerDefineProperty(this, "showMusicModal", _descriptor11, this);
      _defineProperty(this, "formats", _environment.default.APP.formats);
      _initializerDefineProperty(this, "selectedFormat", _descriptor12, this);
      _initializerDefineProperty(this, "selectedLanguage", _descriptor13, this);
      _defineProperty(this, "templateLayerSorting", ['name']);
      _initializerDefineProperty(this, "imageLayersNotSorted", _descriptor14, this);
      _initializerDefineProperty(this, "imageLayers", _descriptor15, this);
      _initializerDefineProperty(this, "videoLayersNotSorted", _descriptor16, this);
      _initializerDefineProperty(this, "videoLayers", _descriptor17, this);
      _initializerDefineProperty(this, "audioLayersNotSorted", _descriptor18, this);
      _initializerDefineProperty(this, "audioLayers", _descriptor19, this);
      _initializerDefineProperty(this, "imageTemplateLayers", _descriptor20, this);
    }
    refreshModel() {
      this.send('refreshRouteModel');
    }
    selectFormat(index) {
      this.selectedFormat = this.formats[index];
    }
    get textLayers() {
      if (!this.selectedTemplate) {
        return [];
      }
      let arr = this.selectedTemplate.templatelayerSet;
      return arr.filter(template => ["data", "text", "int", "long-text"].includes(template.type));
    }
    get colorLayers() {
      if (!this.selectedTemplate) {
        return [];
      }
      let arr = this.selectedTemplate.templatelayerSet;
      return arr.filter(template => "color" === template.type);
    }
    get realtyPhotos() {
      return this.model.project?.jdata?.realty?.photos || [];
    }
    async getFormLayers() {
      this.loading = true;
      let model = this.model;
      let templateLayers = await this.selectedTemplate.templatelayerSet;
      let projectLayers = await model.project.projectlayerSet;
      let textLayers = [];
      let colorLayers = [];
      let subtitlesLayers = [];

      // Set default color values
      templateLayers.filter(layer => layer.type === "color").map(tlayer => {
        if (this.model.user.favorite_color) {
          colorLayers[tlayer.id] = this.model.user.favorite_color;
        } else {
          colorLayers[tlayer.id] = tlayer.jdata.defaultColor;
        }
      });
      projectLayers.map(projectLayer => {
        if (["data", "text", "int", "long-text"].includes(projectLayer.templatelayer.get('type'))) {
          textLayers[projectLayer.templatelayer.get('id')] = projectLayer.value;
        }
        if (projectLayer.templatelayer.get('type') === "image" && projectLayer.templatelayer.get('jdata').subtitle) {
          subtitlesLayers[projectLayer.templatelayer.get('id')] = projectLayer.value;
        }
      });
      this.loading = false;
      return {
        "color": colorLayers,
        "text": textLayers,
        "subtitles": subtitlesLayers
      };
    }
    async selectTemplate(template, saveProjectLayers = false) {
      this.loading = true;
      if (saveProjectLayers) {
        try {
          await this.saveProjectLayers();
        } catch (e) {
          this.notification.error("L'enregistrement des champs a échoué.");
          this.loading = false;
        }
      }
      try {
        let model = this.model;
        model.project.template = template;
        await model.project.save();
        this.selectedTemplate = template;
        this.formLayers = await this.getFormLayers();
      } catch (e) {
        this.notifications.error("Le changement de template a échoué.");
      }
      this.selectedFormat = this.formats[0];
      this.loading = false;
      this.sending = false;
    }
    selectLanguage(language) {
      this.intl.setLocale([language.code, 'fr']);
      this.selectedLanguage = language;
    }
    async saveLayer(value, index) {
      console.log(value, index);
      let templateLayer = this.store.peekRecord('video/template-layer', index);
      let projectLayer = await this.store.query('video/project-layer', {
        "templatelayer": index,
        "project": this.model.project.get('id')
      });
      if (projectLayer.firstObject) {
        projectLayer = projectLayer.firstObject;
      } else {
        projectLayer = this.store.createRecord('video/project-layer', {
          "templatelayer": templateLayer,
          "project": this.model.project
        });
      }
      projectLayer.value = value;
      await projectLayer.save();
    }
    async saveProjectLayers() {
      let textLayers = this.formLayers.text;
      let colorLayers = this.formLayers.color;
      let subtitlesLayers = this.formLayers.subtitles;
      await Promise.all(textLayers.map(this.saveLayer));
      await Promise.all(subtitlesLayers.map(this.saveLayer));
      await Promise.all(colorLayers.map(this.saveLayer));
    }
    async send(e) {
      e.preventDefault();
      this.imagesNotFilled = false;
      this.sending = true;
      let project = this.model.project;
      await this.saveProjectLayers();

      // Verifications (longueur, types, tout est rempli)

      // Vérifie si toutes les images sont complétées
      let filledImagesCount = this.model.project.projectlayerSet.filter(pl => pl.file != null).length;
      let totalImagesCount = this.imageTemplateLayers.length;
      if (filledImagesCount < totalImagesCount) {
        this.notifications.warning('Veuillez compléter toutes les images.');
        this.imagesNotFilled = true;
        this.sending = false;
        return;
      }

      // Check if template is available in this language
      const avaibleLanguages = this.model.project.template.get('languages');
      const selectedLang = this.intl.primaryLocale;
      const selectedLanguageAvailable = avaibleLanguages.any(t => t.code === selectedLang);
      if (!selectedLanguageAvailable) {
        this.notifications.warning(this.intl.t("Le template n'est pas disponible dans la langue sélectionnée."));
        this.sending = false;
        return;
      }

      // Start Rendering
      try {
        await project.startRendering({
          format: this.selectedFormat.comp,
          lang: selectedLang
        });
        this.router.transitionTo('projects.view', project.id);
      } catch (e) {
        this.notifications.error("Une erreur est survenue lors du lancement du rendu. Veuillez réessayer.");
      }
      this.sending = false;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "selectedTemplate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "formLayers", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        "color": [],
        "text": [],
        "subtitles": []
      };
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "imagesNotFilled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "sending", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "showPremiumModal", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "showMusicModal", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "selectedFormat", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.formats[0];
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "selectedLanguage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.model.defaultLanguage;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "imageLayersNotSorted", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "imageLayers", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "videoLayersNotSorted", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "videoLayers", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "audioLayersNotSorted", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "audioLayers", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "imageTemplateLayers", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "refreshModel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "refreshModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectFormat", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectFormat"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectTemplate", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectTemplate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectLanguage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectLanguage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveLayer", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveLayer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveProjectLayers", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveProjectLayers"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "send", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "send"), _class.prototype), _class);
});