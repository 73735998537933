define("clap-front/serializers/application", ["exports", "clap-front/serializers/drf"], function (_exports, _drf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ApplicationSerializer extends _drf.default {
    // cf. https://discuss.emberjs.com/t/excluding-attributes-sent-to-the-server/15381/6
    serializeAttribute(snapshot, json, key, attribute) {
      if (!attribute.options.readOnly) {
        super.serializeAttribute(...arguments);
      }
    }
  }
  _exports.default = ApplicationSerializer;
});