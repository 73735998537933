define("clap-front/templates/components/old/account-menu", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "bPJCxhuo",
    "block": "[[[10,0],[14,0,\"col-sm-12 col-md-5 col-xl-4\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"card\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"card-header\"],[12],[1,\"\\n            \"],[10,\"h5\"],[14,0,\"card-title mb-0\"],[12],[1,\"Mon compte\"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[14,0,\"list-group list-group-flush\"],[14,\"role\",\"tablist\"],[12],[1,\"\\n            \"],[3,\" <a class=\\\"list-group-item list-group-item-action active\\\" data-toggle=\\\"list\\\" href=\\\"#account\\\" role=\\\"tab\\\">\\n                    Vos informations personnelles\\n                  </a> \"],[1,\"\\n            \"],[8,[39,0],[[24,0,\"list-group-item list-group-item-action\"]],[[\"@route\"],[\"account.me\"]],[[\"default\"],[[[[1,\"\\n                Vos informations personnelles\\n            \"]],[]]]]],[1,\"\\n\\n            \"],[8,[39,0],[[24,0,\"list-group-item list-group-item-action\"]],[[\"@route\"],[\"account.password\"]],[[\"default\"],[[[[1,\"\\n                Mot de passe\\n            \"]],[]]]]],[1,\"\\n\\n            \"],[8,[39,0],[[24,0,\"list-group-item list-group-item-action\"]],[[\"@route\"],[\"account.social-networks\"]],[[\"default\"],[[[[1,\"\\n                Réseaux sociaux\\n            \"]],[]]]]],[1,\"\\n\\n            \"],[8,[39,0],[[24,0,\"list-group-item list-group-item-action\"]],[[\"@route\"],[\"account.subscription\"]],[[\"default\"],[[[[1,\"\\n                Abonnement\\n            \"]],[]]]]],[1,\"\\n\\n            \"],[8,[39,0],[[24,0,\"list-group-item list-group-item-action\"]],[[\"@route\"],[\"account.settings\"]],[[\"default\"],[[[[1,\"\\n                Fonctionnalités et notifications\\n            \"]],[]]]]],[1,\"\\n\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"link-to\"]]",
    "moduleName": "clap-front/templates/components/old/account-menu.hbs",
    "isStrictMode": false
  });
});