define("clap-front/controllers/signup", ["exports", "@ember/controller", "@ember/service", "@ember/object", "@glimmer/tracking", "@ember/string"], function (_exports, _controller, _service, _object, _tracking, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let SignupController = _exports.default = (_class = class SignupController extends _controller.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _initializerDefineProperty(this, "request", _descriptor2, this);
      _initializerDefineProperty(this, "cookies", _descriptor3, this);
      _initializerDefineProperty(this, "notifications", _descriptor4, this);
      _initializerDefineProperty(this, "loading", _descriptor5, this);
      _defineProperty(this, "showCGVModal", false);
      _defineProperty(this, "queryParams", ['email']);
    }
    async create(e) {
      e.preventDefault();
      const {
        email,
        password,
        password2,
        cgv
      } = this;
      let cookies = this.cookies;
      let ref = cookies.read('ref');
      this.loading = true;
      if (!cgv) {
        this.notifications.clearAll().warning("Veuillez accepter les conditions générales de vente.", {
          clearDuration: 3500
        });
        this.loading = false;
        return;
      }
      if (password !== password2) {
        this.notifications.clearAll().warning("Les mots de passe ne correspondent pas.");
        this.loading = false;
        return;
      }
      try {
        await this.request.post("users", {
          email: email.toLowerCase(),
          password: password,
          first_name: "",
          last_name: "",
          referral: ref
        });
      } catch (e) {
        e.errors.forEach(function (error) {
          let pointer = error.source.pointer.split('/').pop(-1);
          if (pointer === "password") {
            pointer = "Mot de passe";
          }
          this.notifications.error((0, _string.capitalize)(pointer) + ' : ' + error.detail);
        }, this);
        this.loading = false;
        return;
      }
      try {
        await this.session.authenticate('authenticator:oauth2', email, password);
      } catch (e) {
        this.notifications.error("La connexion automatique a échouée.");
        this.loading = false;
        return;
      }
      this.transitionToRoute('index');
      this.loading = false;
      this.notifications.success("Bienvenue sur clap.video !", {
        clearDuration: 3200
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "request", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "cookies", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "create", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "create"), _class.prototype), _class);
});