define("clap-front/templates/components/old/nav-projects", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "uR01CVvX",
    "block": "[[[10,\"ul\"],[14,0,\"nav nav-projects nav-fill mt-3\"],[12],[1,\"\\n  \"],[10,\"li\"],[14,0,\"nav-item\"],[12],[1,\"\\n    \"],[8,[39,0],[[24,0,\"nav-link\"]],[[\"@route\"],[\"index\"]],[[\"default\"],[[[[1,\"\\n    Terminés\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"li\"],[14,0,\"nav-item\"],[12],[1,\"\\n    \"],[8,[39,0],[[24,0,\"nav-link\"]],[[\"@route\"],[\"projects.draft\"]],[[\"default\"],[[[[1,\"\\n    Brouillons\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"li\"],[14,0,\"nav-item\"],[12],[1,\"\\n    \"],[8,[39,0],[[24,0,\"nav-link\"]],[[\"@route\"],[\"projects.autoclap\"]],[[\"default\"],[[[[1,\"\\n    Générés automatiquement\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"link-to\"]]",
    "moduleName": "clap-front/templates/components/old/nav-projects.hbs",
    "isStrictMode": false
  });
});