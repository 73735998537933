define("clap-front/templates/projects/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "rqKzdMQt",
    "block": "[[[10,0],[14,0,\"no-clap d-flex flex-column align-items-center justify-content-center\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"d-flex flex-column align-items-center justify-content-center\"],[12],[1,\"\\n        \"],[10,\"img\"],[14,\"src\",\"/assets/no_clap.png\"],[14,\"alt\",\"Aucun Clap\"],[14,0,\"mt-4 mt-lg-0\"],[12],[13],[1,\"\\n        \"],[10,\"h2\"],[12],[1,\"Vous n'avez aucune vidéo pour le moment.\"],[13],[1,\"\\n\"],[41,[51,[30,0,[\"model\",\"user\",\"is_embedded\"]]],[[[1,\"            \"],[10,2],[12],[1,\"Créez votre première vidéo en cliquant sur le bouton ci-dessous.\"],[13],[1,\"\\n            \"],[8,[39,1],[[24,3,\"button\"],[24,0,\"clap-btn d-flex align-items-center\"]],[[\"@route\"],[\"create.import-choice\"]],[[\"default\"],[[[[8,[39,2],[[24,0,\"mr-2\"]],[[\"@icon\"],[\"plus\"]],null],[1,\"Nouveau\"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"],[13]],[],false,[\"unless\",\"link-to\",\"fa-icon\"]]",
    "moduleName": "clap-front/templates/projects/index.hbs",
    "isStrictMode": false
  });
});