define("clap-front/templates/connectors/instagram", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "NJvhIhJF",
    "block": "[[[10,0],[14,0,\"fb-message mx-auto\"],[12],[1,\"\\n\"],[41,[30,0,[\"model\"]],[[[41,[30,0,[\"model\",\"error\",\"message\"]],[[[1,\"          Une erreur est survenue : \"],[10,\"i\"],[12],[1,[30,0,[\"model\",\"error\",\"message\"]]],[13],[10,\"br\"],[12],[13],[1,\"\\n          Merci de réessayer en cliquant \"],[8,[39,1],null,[[\"@route\"],[\"connectors\"]],[[\"default\"],[[[[1,\"ici\"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"          La liaison avec Instagram a bien été effectuée. Vous pouvez maintenant envoyer vos vidéos directement sur Instagram.\"],[10,\"br\"],[12],[13],[1,\"\\n          \"],[8,[39,1],[[24,0,\"mt-2 clap-link\"]],[[\"@route\"],[\"connectors\"]],[[\"default\"],[[[[1,\"Retour aux connecteurs\"]],[]]]]],[1,\"\\n\"]],[]]]],[]],null],[13]],[],false,[\"if\",\"link-to\"]]",
    "moduleName": "clap-front/templates/connectors/instagram.hbs",
    "isStrictMode": false
  });
});