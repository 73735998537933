define("clap-front/templates/account", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "MYGiz9Jk",
    "block": "[[[10,0],[14,0,\"container-fluid full-width-container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"col-12 col-xl-4 col-xxxl-3 left-account-column\"],[14,\"tabindex\",\"0\"],[12],[1,\"\\n      \"],[8,[39,0],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"black-veil\"],[12],[13],[1,\"\\n    \"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"account-sidebar\",\"component\",\"-outlet\"]]",
    "moduleName": "clap-front/templates/account.hbs",
    "isStrictMode": false
  });
});