define("clap-front/templates/components/old/nav-pagination", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "oCW9efpl",
    "block": "[[[41,[28,[37,1],[[33,2,[\"meta\",\"previous\"]],[33,2,[\"meta\",\"next\"]]],null],[[[10,0],[14,0,\"col-sm-3 row mt-3 p-2 mx-auto bg-white default-shadow default-bradius\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"col-sm-2 text-center\"],[12],[1,\"\\n\"],[41,[33,2,[\"meta\",\"previous\"]],[[[1,\"          \"],[8,[39,3],null,[[\"@route\",\"@query\"],[[99,4,[\"@route\"]],[28,[37,5],null,[[\"page\"],[[33,2,[\"meta\",\"previous\"]]]]]]],[[\"default\"],[[[[1,[28,[35,6],[\"arrow-left\"],null]]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"col-sm-8 text-center\"],[12],[1,\"\\n        Page \"],[1,[34,7]],[1,\" sur \"],[1,[33,2,[\"meta\",\"pages\"]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"col-sm-2 text-center\"],[12],[1,\"\\n\"],[41,[33,2,[\"meta\",\"next\"]],[[[1,\"            \"],[8,[39,3],null,[[\"@route\",\"@query\"],[[99,4,[\"@route\"]],[28,[37,5],null,[[\"page\"],[[33,2,[\"meta\",\"next\"]]]]]]],[[\"default\"],[[[[1,[28,[35,6],[\"arrow-right\"],null]]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"or\",\"model\",\"link-to\",\"route\",\"hash\",\"fa-icon\",\"page\"]]",
    "moduleName": "clap-front/templates/components/old/nav-pagination.hbs",
    "isStrictMode": false
  });
});