define("clap-front/helpers/format-big-number", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _helper.helper)(function formatBigNumber(positional /*, named*/) {
    console.log(positional[0]);
    return [...`${positional[0]}`].reverse().reduce((acc, v, i) => acc.push((i + 1) % 3 == 0 ? " " + v : v) && acc, []).reverse().join("").trim();
  });
});