define("clap-front/router", ["exports", "@ember/routing/router", "clap-front/config/environment"], function (_exports, _router, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class Router extends _router.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "location", _environment.default.locationType);
      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }
  }
  _exports.default = Router;
  Router.map(function () {
    this.route('login');
    this.route('signup');
    this.route('ask-password-reset');
    this.route('reset-password');
    this.route('logout');
    this.route('account', function () {
      this.route('informations');
      this.route('password');
      this.route('subscription');
      this.route('settings');
    });
    this.route('connectors', function () {
      this.route('facebook');
      this.route('instagram');
    });
    this.route('watch', {
      path: 'watch/:job_uid'
    });
    this.route('projects', function () {
      this.route('view', {
        path: ':project_id'
      });
    });
    this.route('create', function () {
      this.route('new');
      this.route('import-choice');
      this.route('realty-select');
      this.route('informations', {
        path: ':project_id/informations'
      });
    });
  });
});