define("clap-front/components/confirm-modal", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @showModal}}
      <button type="button" class="modal-overlay" {{on "click" @cancelAction}}></button>
      <div class="clap-modal full-page">
          <div class="row">
              <div class="col-12">
                  <h2>{{@title}}</h2>
                  <p>{{@message}}</p>
              </div>
          </div>
  
          <div class="d-flex justify-content-end mt-3">
              <button type="button" class="clap-link mr-3" {{on 'click' @cancelAction}}>{{@cancelButtonText}}</button>
              <button type="button" class="clap-btn {{if @danger "danger"}}" {{on 'click' @confirmAction}}>{{@confirmButtonText}}</button>
          </div>
      </div>
  {{/if}}
  
  */
  {
    "id": "w3wTc/UR",
    "block": "[[[41,[30,1],[[[1,\"    \"],[11,\"button\"],[24,0,\"modal-overlay\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,2]],null],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"clap-modal full-page\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"col-12\"],[12],[1,\"\\n                \"],[10,\"h2\"],[12],[1,[30,3]],[13],[1,\"\\n                \"],[10,2],[12],[1,[30,4]],[13],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[14,0,\"d-flex justify-content-end mt-3\"],[12],[1,\"\\n            \"],[11,\"button\"],[24,0,\"clap-link mr-3\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,2]],null],[12],[1,[30,5]],[13],[1,\"\\n            \"],[11,\"button\"],[16,0,[29,[\"clap-btn \",[52,[30,6],\"danger\"]]]],[24,4,\"button\"],[4,[38,1],[\"click\",[30,7]],null],[12],[1,[30,8]],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[\"@showModal\",\"@cancelAction\",\"@title\",\"@message\",\"@cancelButtonText\",\"@danger\",\"@confirmAction\",\"@confirmButtonText\"],false,[\"if\",\"on\"]]",
    "moduleName": "clap-front/components/confirm-modal.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});