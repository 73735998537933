define("clap-front/initializers/ember-stripe-elements", ["exports", "clap-front/config/environment", "@adopted-ember-addons/ember-stripe-elements/utils/stripe-mock"], function (_exports, _environment, _stripeMock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {
    const application = arguments[1] || arguments[0];
    let stripeConfig = _environment.default.stripe || {};
    application.register('config:stripe', stripeConfig, {
      instantiate: false
    });
    application.inject('service:stripev3', 'config', 'config:stripe');
    if (typeof FastBoot !== 'undefined' || stripeConfig.mock) {
      window.Stripe = _stripeMock.default;
    }
  }
  var _default = _exports.default = {
    name: 'ember-stripe-elements',
    initialize
  };
});