define("clap-front/services/iframe-check", ["exports", "@ember/service", "ember-window-mock"], function (_exports, _service, _emberWindowMock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class IframeCheckService extends _service.default {
    get inIframe() {
      try {
        return _emberWindowMock.default.self !== _emberWindowMock.default.top;
      } catch (e) {
        return true;
      }
    }
  }
  _exports.default = IframeCheckService;
});