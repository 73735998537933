define("clap-front/components/loading-img", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _component2, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <img src={{@src}} alt={{@alt}} {{on "load" (fn (mut this.loaded) true)}} class="{{@class}} {{if this.loaded "" "d-none"}}"/>
  {{#unless this.loaded}}
      <ContentPlaceholders @class="w-100 {{@class}}" @rounded={{true}} as |placeholder|>
          {{placeholder.img}}
      </ContentPlaceholders>
  {{/unless}}
  */
  {
    "id": "s9RIpmqG",
    "block": "[[[11,\"img\"],[16,\"src\",[30,1]],[16,\"alt\",[30,2]],[16,0,[29,[[30,3],\" \",[52,[30,0,[\"loaded\"]],\"\",\"d-none\"]]]],[4,[38,1],[\"load\",[28,[37,2],[[28,[37,3],[[30,0,[\"loaded\"]]],null],true],null]],null],[12],[13],[1,\"\\n\"],[41,[51,[30,0,[\"loaded\"]]],[[[1,\"    \"],[8,[39,5],null,[[\"@class\",\"@rounded\"],[[29,[\"w-100 \",[30,3]]],true]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,4,[\"img\"]]],[1,\"\\n    \"]],[4]]]]],[1,\"\\n\"]],[]],null]],[\"@src\",\"@alt\",\"@class\",\"placeholder\"],false,[\"if\",\"on\",\"fn\",\"mut\",\"unless\",\"content-placeholders\"]]",
    "moduleName": "clap-front/components/loading-img.hbs",
    "isStrictMode": false
  });
  let LoadingImgComponent = _exports.default = (_class = class LoadingImgComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "loaded", _descriptor, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "loaded", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LoadingImgComponent);
});