define("clap-front/controllers/account/subscription", ["exports", "@ember/controller", "@ember/service", "@ember/object", "@glimmer/tracking"], function (_exports, _controller, _service, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let SettingsSubscriptionController = _exports.default = (_class = class SettingsSubscriptionController extends _controller.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "session", _descriptor2, this);
      _initializerDefineProperty(this, "notifications", _descriptor3, this);
      _initializerDefineProperty(this, "request", _descriptor4, this);
      _initializerDefineProperty(this, "moment", _descriptor5, this);
      _defineProperty(this, "showPremiumModal", false);
      _initializerDefineProperty(this, "loading", _descriptor6, this);
    }
    refreshModel() {
      this.send('refreshRouteModel');
    }
    async cancelSubscription() {
      if (confirm("Voulez-vous vraiment résilier votre abonnement Premium ? Votre abonnement continuera jusqu'à la date de fin d'abonnement et ne sera pas renouvelé.")) {
        this.loading = true;
        try {
          let response = await this.request.post('subscription/subscriptions/toggle', {
            "action": "cancel"
          });
          console.log(response);
          if (response['success'] === true) {
            this.notifications.success("Votre demande de résiliation a bien été prise en compte.");
            this.refreshModel();
          }
          this.loading = false;
        } catch (e) {
          if (e.errors) {
            e.errors.forEach(error => {
              this.notifications.error(error.detail);
            });
          } else {
            this.notifications.error("Une erreur est survenue. Merci de réessayer.");
          }
          this.loading = false;
        }
      }
    }
    async reactivateSubscription() {
      let interval = this.model.subscription.plan.get("interval");
      let interval_fr_plural = interval === "year" ? "ans" : "mois";
      let date = this.moment.moment(this.model.subscription.current_period_end).format("DD MMMM YYYY").toString();
      let price = this.model.subscription.plan.get("amount") + "€/" + (interval === "year" ? "an" : "mois");
      let nickname = this.model.subscription.plan.get("nickname");
      let confirmMessage = "Voulez-vous vraiment réactiver votre abonnement " + nickname + " ? Vous serez de nouveau prélevé tous les " + interval_fr_plural + " d'un montant de " + price + " à compter du " + date + ".";
      if (confirm(confirmMessage)) {
        this.loading = true;
        try {
          let response = await this.request.post('subscription/subscriptions/toggle', {
            "action": "reactive"
          });
          if (response['success'] === true) {
            this.notifications.success("Votre résiliation a bien été annulée.");
            this.send("refreshModel");
          }
        } catch (e) {
          if (e.errors) {
            e.errors.forEach(error => {
              this.notifications.error(error.detail);
            });
          } else {
            this.notifications.error("Une erreur est survenue. Merci de réessayer.");
          }
        }
        this.loading = false;
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "request", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "moment", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "refreshModel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "refreshModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelSubscription", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancelSubscription"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reactivateSubscription", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "reactivateSubscription"), _class.prototype), _class);
});