define("clap-front/templates/application", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "T7gJ7wKU",
    "block": "[[[8,[39,0],null,[[\"@position\",\"@zindex\"],[\"top\",\"9999\"]],null],[1,\"\\n\\n\"],[8,[39,1],null,null,null],[1,\"\\n\\n\"],[41,[28,[37,3],[[30,0,[\"session\",\"isAuthenticated\"]]],null],[[[1,\"\\n\"],[41,[28,[37,3],[[30,0,[\"session\",\"data\",\"authenticated\",\"user\",\"is_embedded\"]],[30,0,[\"iframeCheck\",\"inIframe\"]]],null],[[[1,\"        \"],[10,\"style\"],[14,\"media\",\"screen\"],[12],[1,\"\\n            .container{ padding-top:20px!important; }\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,4],null,[[\"@user\"],[[30,0,[\"session\",\"data\",\"authenticated\",\"user\"]]]],null],[1,\"\\n\"]],[]]],[1,\"\\n    \"],[10,\"main\"],[15,0,[29,[[52,[28,[37,3],[[30,0,[\"session\",\"data\",\"authenticated\",\"user\",\"is_embedded\"]],[30,0,[\"iframeCheck\",\"inIframe\"]]],null],\"iframe\"]]]],[12],[1,\"\\n        \"],[46,[28,[37,6],null,null],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[46,[28,[37,6],null,null],null,null,null],[1,\"\\n\"]],[]]]],[],false,[\"notification-container\",\"head-layout\",\"if\",\"and\",\"sidebar\",\"component\",\"-outlet\"]]",
    "moduleName": "clap-front/templates/application.hbs",
    "isStrictMode": false
  });
});