define("clap-front/templates/head", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "0jNaMSWj",
    "block": "[[[1,\"\\n\"],[10,\"title\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n\"]],[\"@model\"],false,[]]",
    "moduleName": "clap-front/templates/head.hbs",
    "isStrictMode": false
  });
});