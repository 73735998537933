define("clap-front/helpers/contains", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.contains = contains;
  _exports.default = void 0;
  function contains([haystack, needle] /*, hash*/) {
    return haystack.includes(needle);
  }
  var _default = _exports.default = (0, _helper.helper)(contains);
});