define("clap-front/helpers/space-number", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.spaceNumber = spaceNumber;
  function spaceNumber(params /*, hash*/) {
    if (params[0]) {
      return parseInt(params[0]).toLocaleString();
    } else {
      return false;
    }
  }
  var _default = _exports.default = (0, _helper.helper)(spaceNumber);
});