define("clap-front/templates/components/old/step-bar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "2mAzAKym",
    "block": "[[[10,0],[14,0,\"top-progress\"],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[\"prog prog-\",[36,0]]]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,1,\"step-bar\"],[14,0,\"mt-4\"],[12],[1,\"\\n  \"],[10,\"ul\"],[14,0,\"list-unstyled multi-steps\"],[12],[1,\"\\n    \"],[10,\"li\"],[15,0,[52,[28,[37,2],[[33,0],\"1\"],null],\"is-active\"]],[12],[1,\"\\n      \"],[1,[52,[33,3,[\"name\"]],[28,[37,4],[\"Modèle \\\"\",[33,3,[\"name\"]],\"\\\"\"],null],\"Choix du modèle\"]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"li\"],[15,0,[52,[28,[37,2],[[33,0],\"2\"],null],\"is-active\"]],[12],[1,\"\\n\"],[41,[28,[37,5],[[33,0],\"2\"],null],[[[1,\"        Rédaction des textes\\n\"]],[]],[[[1,\"        \"],[8,[39,6],null,[[\"@route\",\"@model\"],[\"projects.edit\",[33,7,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n          Rédaction des textes\\n        \"]],[]]]]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n    \"],[10,\"li\"],[15,0,[52,[28,[37,2],[[33,0],\"3\",[33,8],[33,9],[33,0],\"3\"],null],\"is-active\"]],[12],[1,\"\\n\"],[41,[28,[37,10],[[33,0],\"4\"],null],[[[1,\"        \"],[8,[39,6],null,[[\"@route\",\"@model\"],[\"projects.pictures\",[33,7,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n          Choix des images\\n        \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"        Choix des images\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n    \"],[10,\"li\"],[15,0,[52,[28,[37,2],[[33,0],\"4\"],null],\"is-active\"]],[12],[1,\"\\n      Validation\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"step\",\"if\",\"eq\",\"template\",\"concat\",\"lte\",\"link-to\",\"project\",\"or\",\"gt\",\"gte\"]]",
    "moduleName": "clap-front/templates/components/old/step-bar.hbs",
    "isStrictMode": false
  });
});