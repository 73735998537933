define("clap-front/templates/components/old/realty-select", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "331KgtKL",
    "block": "[[[41,[33,1],[[[10,0],[14,0,\"placeholder-select\"],[12],[1,\"Nous récupérons vos biens \"],[1,[28,[35,2],[\"spinner\"],[[\"spin\"],[true]]]],[1,\" \"],[13],[1,\"\\n\"]],[]],[[[41,[33,3],[[[1,\"    \"],[8,[39,4],null,[[\"@options\",\"@matcher\",\"@placeholder\",\"@searchPlaceholder\",\"@selected\",\"@onchange\"],[[99,3,[\"@options\"]],[99,5,[\"@matcher\"]],\"Trouvez votre bien par référence ou par nom\",\"Trouvez votre bien par référence ou par nom\",[99,6,[\"@selected\"]],[28,[37,7],[[30,0],\"selectRealty\",[33,6,[\"id\"]]],null]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,1,[\"fr_title\"]],[[[1,\"          \"],[1,[30,1,[\"reference\"]]],[1,\" - \"],[1,[30,1,[\"fr_title\"]]],[1,\"\\n\"]],[]],null],[1,\"    \"]],[1]]]]],[1,\"\\n\\n\"]],[]],null]],[]]]],[\"realty\"],false,[\"if\",\"loading\",\"fa-icon\",\"realties\",\"power-select\",\"realtiesSearch\",\"selectedRealty\",\"action\"]]",
    "moduleName": "clap-front/templates/components/old/realty-select.hbs",
    "isStrictMode": false
  });
});