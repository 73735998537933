define("clap-front/services/session", ["exports", "ember-simple-auth/services/session", "@ember/application"], function (_exports, _session, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ExtendedSessionService extends _session.default {
    handleAuthentication(routeAfterAuthentication) {
      const applicationRoute = (0, _application.getOwner)(this).lookup('route:application');
      try {
        applicationRoute.wsLogin();
      } catch (e) {
        console.log("websocket login failed: ", e);
        try {
          applicationRoute.send('wsLogin');
        } catch (e) {
          console.log("websocket login failed: ", e);
        }
      }
      super.handleAuthentication(routeAfterAuthentication);
    }
  }
  _exports.default = ExtendedSessionService;
});