define("clap-front/routes/create/informations", ["exports", "@ember/routing/route", "rsvp", "@ember/service", "@ember/object"], function (_exports, _route, _rsvp, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let CreateInformationsRoute = _exports.default = (_class = class CreateInformationsRoute extends _route.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _initializerDefineProperty(this, "notifications", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _defineProperty(this, "titleToken", "Création d'une vidéo");
      _defineProperty(this, "queryParams", {
        lang: {
          refreshModel: false,
          as: 'defaultLang'
        }
      });
    }
    beforeModel(transition) {
      this.session.requireAuthentication(transition, 'login');
    }
    async model(params) {
      let user_id = this.session.data.authenticated.user.id;
      let project;
      try {
        project = await this.store.findRecord('video/project', params.project_id, {
          reload: true
        });
      } catch (error) {
        this.notifications.warning(`Le projet #${params.project_id} n'existe pas ou vous ne vous appartient pas.`);
        return this.transitionTo('index');
      }
      if (project.draft === false) {
        return this.transitionTo('projects.view', project.id);
      }
      const templates = await this.store.query("video/template", {
        show_in_app: true
      });
      const languages = await this.store.findAll("users/language");

      // Default language : french if no lang param is given, or english is the given lang is not supported yet
      const lang = params.lang;
      const defaultLanguage = lang ? languages.findBy("code", lang) || languages.findBy("code", "en") : languages.findBy("code", "fr");
      return (0, _rsvp.hash)({
        user: this.store.find('user', user_id),
        templates: templates,
        languages: languages,
        defaultLanguage: defaultLanguage,
        project: this.store.findRecord("video/project", params.project_id),
        plans: this.store.findAll("subscription/plan"),
        musics: this.store.findAll("video/music")
      });
    }
    async setupController(controller, model) {
      super.setupController(controller, model);
      controller.selectedLanguage = model.defaultLanguage;
      controller.intl.setLocale([controller.selectedLanguage.code, 'fr']);
      let template = await model.project.template;
      if (template && template.get('id')) {
        controller.selectedTemplate = template;
        controller.formLayers = await controller.getFormLayers();
      } else {
        let user = await model.user;
        if (user.is_subscribed) {
          controller.selectTemplate(model.templates.firstObject);
        } else {
          let firstFreeTemplate = model.templates.filter(template => template.free).firstObject;
          controller.selectTemplate(firstFreeTemplate);
        }
      }
      controller.imagesNotFilled = false;
    }
    refreshRouteModel() {
      this.refresh();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notifications", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "refreshRouteModel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "refreshRouteModel"), _class.prototype), _class);
});