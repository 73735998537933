define("clap-front/authenticators/oauth2", ["exports", "rsvp", "@ember/runloop", "@ember/array", "@ember/utils", "@ember/polyfills", "ember-simple-auth/authenticators/oauth2-password-grant", "clap-front/config/environment"], function (_exports, _rsvp, _runloop, _array, _utils, _polyfills, _oauth2PasswordGrant, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const assign = _polyfills.assign;
  var _default = _exports.default = _oauth2PasswordGrant.default.extend({
    serverTokenEndpoint: _environment.default.APP.API_HOST + "/api/token-auth",
    authenticate(identification, password, scope = [], headers = {}) {
      return new _rsvp.default.Promise((resolve, reject) => {
        const data = {
          'grant_type': 'password',
          email: identification,
          password
        };
        const serverTokenEndpoint = this.serverTokenEndpoint;
        const useResponse = this.rejectWithResponse;
        const scopesString = (0, _array.makeArray)(scope).join(' ');
        if (!(0, _utils.isEmpty)(scopesString)) {
          data.scope = scopesString;
        }
        this.makeRequest(serverTokenEndpoint, data, headers).then(response => {
          (0, _runloop.run)(() => {
            if (!this._validate(response)) {
              reject('access_token is missing in server response');
            }
            const expiresAt = this._absolutizeExpirationTime(response['expires_in']);
            this._scheduleAccessTokenRefresh(response['expires_in'], expiresAt, response['refresh_token']);
            if (!(0, _utils.isEmpty)(expiresAt)) {
              response = assign(response, {
                'expires_at': expiresAt
              });
            }
            resolve(response);
          });
        }, response => {
          (0, _runloop.run)(null, reject, useResponse ? response : response.responseJSON || response.responseText);
        });
      });
    }
  });
});