define("clap-front/services/event-bus", ["exports", "@ember/object/evented", "@ember/service"], function (_exports, _evented, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // class EventHolder extends Evented {
  //     constructor() {

  //     }
  // }

  class EventBusService extends _service.default {
    stopPlayers() {
      this.trigger('stopPlayers');
    }
  }
  var _default = _exports.default = EventBusService.extend(_evented.default);
});