define("clap-front/templates/application-error", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "LyXp7n2Y",
    "block": "[[[10,0],[14,0,\"error-container container mt-5\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"text-center col-sm-12 mt-5\"],[12],[1,\"\\n      \"],[10,\"img\"],[14,0,\"clap-logo mt-5\"],[14,\"alt\",\"clap.video\"],[14,\"src\",\"/assets/clap-logo-dark.png\"],[14,\"width\",\"200\"],[12],[13],[1,\"\\n      \"],[10,\"h1\"],[14,0,\"mt-5\"],[12],[1,\"Oups !\"],[13],[1,\"\\n      \"],[10,2],[14,0,\"mt-3 mb-5\"],[12],[1,\"Il semble y avoir une erreur de notre côté.\"],[13],[1,\"\\n      \"],[8,[39,0],[[24,0,\"clap-btn mt-5\"]],[[\"@route\"],[\"index\"]],[[\"default\"],[[[[1,\"Retour à l'accueil\"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"link-to\"]]",
    "moduleName": "clap-front/templates/application-error.hbs",
    "isStrictMode": false
  });
});