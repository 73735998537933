define("clap-front/templates/connectors/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "oMtmXUr9",
    "block": "[[[10,0],[14,0,\"container-fluid clap-container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n\\n    \"],[10,0],[14,0,\"col-12 connectors-title\"],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,0,[\"model\",\"user\",\"is_embedded\"]],[30,0,[\"iframeCheck\",\"inIframe\"]]],null],[[[1,\"        \"],[8,[39,2],[[24,0,\"clap-link\"]],[[\"@route\"],[\"index\"]],[[\"default\"],[[[[1,\"\\n          \"],[8,[39,3],null,[[\"@icon\",\"@prefix\"],[\"long-arrow-left\",\"fal\"]],null],[1,\"\\n          \"],[10,1],[12],[1,\"Retour à mes vidéos\"],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"      \"],[10,\"h1\"],[12],[1,\"Connecteurs\"],[13],[1,\"\\n      \"],[10,\"h2\"],[12],[1,\"Connecter l'ensemble de vos plateformes à clap.video\"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"model\",\"user\",\"isConnectedViaTopi\"]],[[[1,\"      \"],[10,0],[14,0,\"col-12 d-flex my-2\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"topi-card\"],[12],[1,\"\\n          \"],[10,\"img\"],[14,0,\"mr-1\"],[14,\"src\",\"/assets/topi.png\"],[14,\"alt\",\"Topi\"],[12],[13],[1,\"\\n          \"],[10,1],[12],[1,\"Vous êtes actuellement connecté via Topi\"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,0],[14,0,\"col-12\"],[12],[1,\"\\n      \"],[10,\"h3\"],[15,0,[29,[\"subtitle subheader \",[52,[30,0,[\"model\",\"user\",\"isConnectedViaTopi\"]],\"mt-2\"]]]],[12],[1,\"Réseaux sociaux\"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[1,\"    \"],[8,[39,4],null,[[\"@socialNetwork\",\"@connected\",\"@multiplePages\",\"@link\",\"@username\",\"@pages\",\"@available\"],[\"facebook\",[30,0,[\"model\",\"facebookUser\"]],true,[30,0,[\"facebookUrl\"]],[30,0,[\"model\",\"facebookUser\",\"name\"]],[30,0,[\"model\",\"facebookPages\"]],true]],null],[1,\"\\n\"],[1,\"\\n\"],[1,\"\\n\"],[1,\"\\n\"],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"and\",\"link-to\",\"fa-icon\",\"social-card\"]]",
    "moduleName": "clap-front/templates/connectors/index.hbs",
    "isStrictMode": false
  });
});